import React from "react"
import { Link } from "gatsby"
import HeroImage from "../../../contents/Home/HeroImage"
import SvgDevices from "../../../contents/Home/SvgDevices"
import SvgDeadPixelRepair from "../../../contents/Home/SvgDeadPixelRepair"
import SvgStuckPixelRepair from "../../../contents/Home/SvgStuckPixelRepair"
import SEO from "../../../components/Seo"
const HomePage = () => {
  return (
    <>
      <SEO
        title="Test de Pixel"
        lang="fr"
        description="Testez les pixels morts, les pixels bloqués, réparez les pixels morts et les pixels bloqués sur vos moniteurs, écrans, tablettes, ordinateurs et téléviseurs."
        keywords="test de pixel mort, test de pixel bloqué, réparation de pixel bloqué, réparation de pixel mort, test de pixel bloqué, qu'est-ce qu'un pixel mort, qu'est-ce qu'un pixel bloqué, qu'est-ce qu'un pixel bloqué, pixel mort différence de pixel bloqué, test de pixel mort, test de pixel, pixel mort, pixel mort, test de pixel, pixel bloqué, réparation de pixel en ligne"
      />
      <h1 className="pb-0 mb-0 text-center">
        Vérifiez les pixels <span className="text-indigo-600">morts</span> ou{" "}
        <span className="text-red-500">bloqués!</span>
      </h1>

      <HeroImage className="max-w-full px-3 md:px-10" />

      <p className="lead">
        Vous pouvez tester les pixels morts ou bloqués sur vos téléphones
        mobiles, tablettes, téléviseurs et ordinateurs via le navigateur et
        utiliser l'outil gratuit de réparation de pixels sans aucune
        installation.
      </p>
      <h2>Test des pixels morts ou bloqués</h2>
      <p>
        Que ce soit une tablette, un téléphone ou un moniteur ... Lorsque vous
        achetez un appareil avec un écran, vous devez faire le test des pixels
        morts et le test des pixels bloqués après l'installation.
      </p>
      <p>
        Plus tôt vous remarquerez un défaut potentiel du produit que vous avez
        acheté, plus il sera facile de traiter le retour et l'échange. De plus,
        ce processus ne prend pas beaucoup de temps, vous pouvez facilement
        tester les pixels morts et les pixels bloqués en quelques minutes.
      </p>
      <SvgDevices className="max-w-full px-3 md:px-10" />

      <p>
        Vous pouvez démarrer le test en accédant à la page correspondante en
        cliquant sur le bouton <code>Test Pixel Mort</code> ci-dessous. Dans la
        page qui s'ouvre, vous trouverez des informations détaillées sur le test
        des pixels morts et des pixels bloqués ainsi que sur l'outil de test.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/test-de-pixels-morts"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Tester le Pixel Mort</span>
        </Link>
      </div>
      <h2>
        Comment réparer un pixel mort, résoudre un problème de pixel mort?
      </h2>
      <p>
        Un pixel mort signifie qu'un pixel à l'écran a atteint la fin de sa vie.
        Comme il ne prend pas d'énergie, il est généralement de couleur noire.
        Il n'y a pas de solution logicielle pour résoudre le problème des pixels
        morts, généralement le remplacement du panneau et de l'écran est
        nécessaire pour cela. Appuyer doucement vers le bas peut fonctionner
        s'il y a des pixels morts sur les écrans LCD causés par l'émission de
        cristaux liquides.
      </p>
      <SvgDeadPixelRepair className="max-w-full px-3 md:px-10" />

      <p>
        Sur la page que vous ouvrirez en cliquant sur le bouton{" "}
        <code>Réparer les Pixels Morts</code> ci-dessous, il est expliqué étape
        par étape comment supprimer vos pixels morts en appuyant doucement vers
        le bas.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/correction-des-pixels-morts"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Réparer les Pixels Morts</span>
        </Link>
      </div>
      <h2>
        Comment réparer un pixel bloqué, résoudre un problème de pixel bloqué?
      </h2>
      <p>
        Les couleurs qui ne changent pas sur votre écran,{" "}
        <strong className="italic font-bold">
          surtout si leur couleur n'est ni noir ni blanc
        </strong>
        , ne signifient pas toujours qu'il s'agit de pixels morts. Les pixels
        qui restent dans une couleur pendant une longue période peuvent rester
        bloqués dans des couleurs telles que{" "}
        <span className="text-red-600">le rouge</span>,{" "}
        <span className="text-green-400">le vert</span>,{" "}
        <span className="text-blue-600">le bleu</span>.
      </p>
      <SvgStuckPixelRepair className="max-w-full px-3 md:px-10" />
      <p>
        Il existe un outil de réparation gratuit pour les pixels bloqués sur la
        page qui s'ouvre lorsque vous cliquez sur le bouton{" "}
        <code>Réparer les Pixels Bloqués</code> ci-dessous. En plus des
        informations sur l'utilisation de l'outil de réparation de pixels
        bloqués, vous pouvez également trouver des informations sur ce qu'est le
        pixel bloqué et la différence entre pixel bloqué et pixel mort sur la
        page de réparation de pixel bloqué.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/correction-des-pixels-bloques"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Réparer le Pixel Bloqué</span>
        </Link>
      </div>
    </>
  )
}
export default HomePage
